import * as Sentry from '@sentry/browser'
import posthog from 'posthog-js'

const sentryData = document.getElementById('sentry-config').dataset

/**
 * Calculates the sample rate based on the current sentry environment.
 * Same function exists in config.base, Django's base config.
 * @returns {number} The sample rate.
 */
function sampleRate () {
  switch (sentryData.sentryEnvironment) {
    case 'development':
      return 1
    case 'testing':
      return 1
    case 'staging':
      return 0.75
    case 'production':
      return 0.2
  }
}

const integrations = [
  Sentry.replayIntegration({
    maskAllText: false,
    maskAllInputs: false,
    blockAllMedia: false,
    mask: ['.passwordinput']
  }),
  Sentry.replayCanvasIntegration(),
  Sentry.browserTracingIntegration(),
  Sentry.feedbackIntegration({
    enableScreenshot: true,
    triggerLabel: 'Feedback',
    formTitle: 'Feedback/Bug Report',
    submitButtonLabel: 'Submit',
    messagePlaceholder: 'Please provide any feedback or describe any errors you encountered.',
    colorScheme: 'system'
  }),
  Sentry.captureConsoleIntegration()
]

if (window.posthog) {
  integrations.push(posthog.sentryIntegration({
    organization: sentryData.sentryOrg,
    projectId: sentryData.sentryProjId
  }))
}

Sentry.init({
  dsn: sentryData.sentryDsn,
  environment: sentryData.sentryEnvironment,
  release: sentryData.sentryRelease,
  debug: false, // sentryData.sentryEnvironment !== 'production',
  tracesSampleRate: sampleRate(),
  attachStacktrace: true,
  replaysSessionSampleRate: sampleRate(),
  replaysOnErrorSampleRate: 1.0,
  sendDefaultPii: true,
  integrations,

  beforeSend (event, hint) {
    let sampleRate = parseInt(sentryData.sentryTracesSampleRat)
    if (event && event.level && (event.level === 'fatal' || event.level === 'error' || event.level === 'force')) {
      sampleRate = 1
    }

    const random = Math.random()
    if (random <= sampleRate) {
      return event
    }

    return null
  },

  ignoreErrors: [
    // Random plugins/extensions
    'top.GLOBALS',
    // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
    'originalCreateNotification',
    'canvas.contentDocument',
    'MyApp_RemoveAllHighlights',
    'http://tt.epicplay.com',
    "Can't find variable: ZiteReader",
    'jigsaw is not defined',
    'ComboSearch is not defined',
    'http://loading.retry.widdit.com/',
    'atomicFindClose',
    // Facebook borked
    'fb_xd_fragment',
    // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to reduce this. (thanks @acdha)
    // See http://stackoverflow.com/questions/4113268/how-to-stop-javascript-injection-from-vodafone-proxy
    'bmi_SafeAddOnload',
    'EBCallBackMessageReceived',
    // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
    'conduitPage',
    // Generic error code from errors outside the security sandbox
    // You can delete this if using raven.js > 1.0, which ignores these automatically.
    'Script error.',
    // Avast extension error
    '_avast_submit'
  ],
  denyUrls: [
    // Google Adsense
    /pagead\/js/i,
    // Facebook flakiness
    /graph\.facebook\.com/i,
    // Facebook blocked
    /connect\.facebook\.net\/en_US\/all\.js/i,
    // Woopra flakiness
    /eatdifferent\.com\.woopra-ns\.com/i,
    /static\.woopra\.com\/js\/woopra\.js/i,
    // Chrome extensions
    /extensions\//i,
    /^chrome:\/\//i,
    // Other plugins
    /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
    /webappstoolbarba\.texthelp\.com\//i,
    /metrics\.itunes\.apple\.com\.edgesuite\.net\//i
  ]
})
if (sentryData.user) {
  Sentry.setUser({ email: sentryData.user })
}

window.Sentry = Sentry
